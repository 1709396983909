'use client';

import { cn } from '@/src/lib/utils';
import { usePathname } from 'next/navigation';
import FreeTrialClassLink from '../features/FreeTrialClassLink';
import { TypographyH2 } from '../typography/TypographyH2';
import { TypographyLead } from '../typography/TypographyLead';

const showOnRoutes: string[] = [
	'/',
	'/about',
	'/classes',
	'/faqs',
	'/reviews',
	'/syllabus',
];

const FreeTrialClassCTA = () => {
	const pathname = usePathname();

	return (
		showOnRoutes.includes(pathname) && (
			<aside
				style={{
					position: 'relative',
					backgroundColor: '#000000',
				}}
				className="bg-jujitsu"
			>
				<section className="mx-auto max-w-screen-xl rounded-full bg-gradient-radial from-black/80 via-black/20 via-50% px-4 py-16 text-center sm:py-24 lg:px-6">
					<TypographyH2 className={cn('pt-5 text-white/95')}>
						Ready to get started?
					</TypographyH2>
					<TypographyLead className="text-gray-400 md:text-lg">
						Sign up for a free 2 hour trial class
					</TypographyLead>

					<div>
						<FreeTrialClassLink />
					</div>
				</section>
			</aside>
		)
	);
};

export default FreeTrialClassCTA;
