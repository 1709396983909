import { cn } from '@/src/lib/utils';

export function TypographyH2({ children, className }) {
	return (
		<h2
			className={cn(
				'mb-6 scroll-m-20 text-balance text-4xl font-black leading-tight text-black md:text-5xl',
				className
			)}
		>
			{children}
		</h2>
	);
}
