'use client';

import ScrollToTop from 'react-scroll-to-top';

import Image from 'next/image';
import arrowDownIcon from '/public/vectors/arrow-down-icon.svg';

const ScrollToTopButton = () => {
	return (
		<ScrollToTop
			smooth
			component={
				<Image
					src={arrowDownIcon}
					alt="Arrow icon"
					aria-hidden="true"
					className="size-4 shrink-0 rotate-180"
				/>
			}
			className={'scroll-to-top-button'}
		/>
	);
};

export default ScrollToTopButton;
