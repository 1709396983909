'use client';

import { Button } from '@/src/components/ui/button';
import {
	Sheet,
	SheetClose,
	SheetContent,
	SheetHeader,
	SheetTrigger,
} from '@/src/components/ui/sheet';
import { navVariants } from '@/src/lib/motion';
import MotionWrapper from '@/src/lib/motionWrapper';
import { headerData } from '@/src/shared/data';
import { MenuLink } from '@/src/shared/types';
import { m } from 'framer-motion';
import { Menu } from 'lucide-react';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import FreeTrialClassLink from '../features/FreeTrialClassLink';
import Spacer from '../features/Spacer';
import logotype from '/public/vectors/logotype.svg';
import plusIcon from '/public/vectors/plus-circle-icon.svg';
import risingSun from '/public/vectors/rising-sun-white-transparent-icon.svg';

const Navbar = () => {
	const { links } = headerData;
	const currentRoute = usePathname();

	return (
		<MotionWrapper>
			<m.nav
				variants={navVariants}
				initial="hidden"
				whileInView="show"
				viewport={{ once: true, amount: 0 }}
				aria-label="Global"
				className="w-full bg-black"
			>
				<div className="mx-auto flex max-w-7xl items-center justify-between gap-x-4 bg-black p-6 lg:px-8">
					<div className="flex max-w-7xl lg:flex-1">
						<Link key="homepage" href="/" className="-m-1.5 p-1.5">
							<span className="sr-only">Shockwave Jujitsu</span>
							<div className="flex items-center gap-3.5">
								<Image
									src={risingSun}
									alt="Rising sun"
									aria-hidden="true"
									className="size-8"
									priority={true}
								/>
								<Image
									src={logotype}
									alt="Shockwave Jujitsu"
									aria-hidden="true"
									className="h-5 w-auto shrink-0 lg:h-6"
									priority={true}
								/>
							</div>
						</Link>
					</div>
					<div className="hidden xl:flex xl:gap-x-6">
						{links &&
							links.map((item: MenuLink) => {
								const isActive = currentRoute === item.href;
								return (
									<Link
										key={'desktop-menu-item-' + item.label}
										href={item.href as string}
										data-active={isActive}
										target="_self"
										className="text-sm font-normal leading-6 text-gray-400 transition-all duration-300 hover:font-bold hover:text-white data-[active=true]:text-white"
									>
										{item.label}
									</Link>
								);
							})}
					</div>
					<div className="ml-auto hidden flex-1 items-center justify-end sm:flex">
						<FreeTrialClassLink />
					</div>
					<div className="flex xs:hidden">
						{/* Spacer to fill height of hidden FreeTrialClassModal button */}
						<Spacer className="h-[40px]" />
					</div>

					<div className="flex xl:hidden">
						<Sheet>
							<SheetTrigger asChild>
								<Menu
									className="size-6 text-white"
									role="dialog"
								/>
							</SheetTrigger>
							<SheetContent className="border-0 bg-black">
								<SheetHeader className="-mt-2 text-white">
									<SheetClose asChild>
										<Link href="/">
											<Image
												src={risingSun}
												alt="Rising sun icon"
												aria-hidden="true"
												className="size-8"
											/>
										</Link>
									</SheetClose>
								</SheetHeader>
								<div className="mt-6 flow-root">
									<div className="-my-2">
										{links &&
											links.map((item) => {
												const isActive =
													currentRoute === item.href;
												return (
													<SheetClose
														asChild
														key={
															'mobile-menu-item-' +
															item.label
														}
													>
														<Link
															href={
																item.href as string
															}
															className="-mx-3 block p-3 font-recursive text-sm font-normal leading-7 text-gray-400 transition-colors hover:bg-white/10 hover:text-white data-[active=true]:text-white"
															data-active={
																isActive
															}
														>
															{item.label}
														</Link>
													</SheetClose>
												);
											})}

										<div className="ml-auto flex items-center justify-between lg:w-auto">
											<div className="w-max flex-wrap justify-end pt-6">
												<SheetClose
													asChild
													key={'close'}
												>
													{/* <FreeTrialClassLink /> would not trigger the close */}
													<Link href="/classes">
														<Button
															variant="default"
															className="bg-yellow-secondary font-recursive font-medium tracking-tight text-black ring-offset-black transition-all duration-300 hover:bg-white hover:font-semibold"
														>
															<Image
																src={plusIcon}
																alt="Add icon"
																aria-hidden="true"
																className="mr-1 size-4 shrink-0"
															/>
															Book Free Trial
															Class
														</Button>
													</Link>
												</SheetClose>
											</div>
										</div>
									</div>
								</div>
							</SheetContent>
						</Sheet>
					</div>
				</div>
			</m.nav>
		</MotionWrapper>
	);
};

export default Navbar;
