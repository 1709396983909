'use client';

import dynamic from 'next/dynamic';
import { useState } from 'react';

const CreditsActive = dynamic(() => import('./Credits'), {
	ssr: false,
	loading: () => <p>Loading...</p>,
});

export default function CreditsLink() {
	const [clicked, setClicked] = useState(false);

	if (clicked) {
		return <CreditsActive />;
	}

	return (
		<a
			className="underline-offset-4 transition-all duration-300 hover:cursor-pointer hover:underline"
			onClick={() => setClicked(!clicked)}
		>
			Credits
		</a>
	);
}
