'use client';

import { navVariants } from '@/src/lib/motion';
import MotionWrapper from '@/src/lib/motionWrapper';
import { announcementData } from '@/src/shared/data';
import { m } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import arrowRightIcon from '/public/vectors/arrow-right-icon.svg';

export default function Announcement() {
	const { title, callToAction } = announcementData;

	return (
		<MotionWrapper>
			<m.div
				variants={navVariants}
				initial="hidden"
				whileInView="show"
				viewport={{ once: true, amount: 0 }}
				id="announcement"
				tabIndex={-1}
				className="z-50 hidden w-full justify-between bg-charcoal px-4 py-2 sm:flex"
			>
				<div className="mx-auto flex items-center">
					<p className="text-xs font-medium text-white md:my-0">
						<span className="mr-2 hidden rounded bg-yellow-tertiary px-2.5 py-0.5 font-inter text-yellow-secondary md:inline-flex">
							{title}
						</span>
						<span className="mr-2">{callToAction.text}</span>
						<Link
							href={callToAction.href}
							className="group inline-flex items-center text-xs font-medium text-yellow-primary underline-offset-4 duration-300 hover:underline"
						>
							{callToAction.linkText}
							<Image
								src={arrowRightIcon}
								alt="Arrow icon"
								aria-hidden="true"
								className="ml-1 size-4 transition-all ease-in-out group-hover:translate-x-1"
							/>
						</Link>
					</p>
				</div>
			</m.div>
		</MotionWrapper>
	);
}
