import { cn } from '@/src/lib/utils';

export function TypographyLead({ children, className }) {
	return (
		<p
			className={cn(
				'font-regular mx-auto max-w-xl text-balance pb-8 text-md text-black lg:text-lg',
				className
			)}
		>
			{children}
		</p>
	);
}
